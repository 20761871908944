<template>
    <div>
        <div class="title color-white">
            <div class="content-box color-white">
                <h1 class="pb-30 pt-50 my-0 ml-50">Contact Us</h1>
                <p class="my-0 ml-50 font-20">If you encounter any problems during use,<br/>please contact us using the following<br/>contact information!</p>
            </div>
        </div>
        <el-card class="content-box bg-white my-20">
            <div class=" d-flex-center">
                <h3 class="mr-auto my-0">Contact Details</h3>
                <el-button class="ml-20" type="primary" icon="el-icon-help" plain @click="$nav.push('/help')">{{ $t('help.title') }} >></el-button>
            </div>
        </el-card>
        <div class="content-box mb-50">
            <div v-if="list.length">
                <div v-for="(item, index) in list" :key="index" class="is-link d-flex-center p-20 my-20 bg-white border-radius-8" @click="linkToKefu(index)">
                    <div class="icon border-radius-6 mr-16 icon-36" :class="'icon-' + item.type" />
                    <span class="mr-auto font-bold font-16 color-333">{{ item.title }}</span>
                    <span v-if="item.type != 'email'" class="font-12 color-666">@</span>
                    <span class="font-16 color-666" selectable>{{ item.account }}</span>
                    <div class="icon icon-forward icon-14 ml-16" />
                </div>
            </div>
            <el-empty v-else-if="loaded" :image-size="200" description="No contact information found"></el-empty>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loaded: false,
            list: []
        }
    },
    created: function () {
        this.fetchData()
    },
    methods: {
        fetchData: async function() {
            const { data } = await this.$request.get('kefu')
            this.list = data
            this.loaded = true
            // this.$store.commit('kefu', data)
        },
        linkToKefu: function(index){
            let url = null
            if(this.list[index].type === 'email'){
                url = 'mailto:' + this.list[index].account
            }else if(this.list[index].type === 'whatsapp'){
                url = 'https://wa.me/' + this.list[index].account
            }else if(this.list[index].type === 'telegram'){
                url = 'https://t.me/' + this.list[index].account
            }
            if(url === null) {
                return
            }
            this.openURL(url)
        },
        openURL: function(url){
            const a = document.createElement('A')
            a.target = '_blank'
            a.href = url
            if (MouseEvent) {
                const mevt = new MouseEvent('click', {
                    div: window,
                    bubbles: false,
                    cancelable: true
                });
                a.dispatchEvent(mevt);
            } else if ( document.createEvent ) {
                const evt = document.createEvent('MouseEvents');
                evt.initEvent('click', false, false);
                a.dispatchEvent(evt);
            }
        }
    }
}
</script>
<style lang="scss">
.title{
    background-image: url('~@/assets/help/banner.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    height: 280px;
}
.icon-email{
    background-image: url('~@/assets/icon/kefu-mail.svg');
}
.icon-telegram{
    background-image: url('~@/assets/icon/telegram.svg');
}
.icon-whatsapp{
    background-image: url('~@/assets/icon/whatsapp.svg');
}
</style>